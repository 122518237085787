import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';
import { Usuario } from '../../models/usuario';
import { Regional } from '../../../administracion/models/regional';
import { UntypedFormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidemenu-toolbar',
  templateUrl: './sidemenu-toolbar.component.html',
  styleUrls: ['./sidemenu-toolbar.component.scss']
})
export class SidemenuToolbarComponent implements OnInit {

  usuario: Usuario;
  regionalControl: UntypedFormControl;
  regional: Regional = null;
  regionalSeleccionada: Boolean = false;
  environment = environment;

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.regionalControl = new UntypedFormControl('');
    this.auth.currentUser().subscribe(usuario => this.usuario = usuario);
    
    this.auth.currentRegional().subscribe(regional => {
      this.regionalControl.setValue(regional);
      if(regional != null){
        this.regionalSeleccionada = true;
      }
    });
  }

  selection(event) {
    this.regional = event.value;
    this.auth.setRegional('currentRegional', this.regional);
    this.regionalSeleccionada = true;
  }

  compare(r1: Regional, r2: Regional) {
    return r1 && r2 ? r1.id === r2.id : r1 === r2;
  }

}
