import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Medico } from '../models/medico';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../core/models/pagination';
import { Prestacion } from '../models/prestacion';
import { AuthService } from '../../authentication/services/auth.service';
import { Regional } from '../models/regional';
import { Departamento, EstadoCivil, Etnia, Expedido, IdentidadGenero, Idioma, Municipio, NivelInstruccion, Ocupacion, OrientacionSexual, Pais, Referencia } from '../models/configuracion';
import { PresentacionFarmaco } from '../../core/models/presentacion_farmaco';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  regional: Regional;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.auth.currentRegional().subscribe(regional => this.regional = regional);
  }

  searchMedico(search: string) {
    return this.http.get<Pagination<Medico>>(`${environment.baseUrl}/administracion/medicos/`,
      { params: { search }, headers: { regional: this.regional.codigo } });
  }

  searchPrestaciones(search: string) {
    return this.http.get<Pagination<Prestacion>>(
      `${environment.baseUrl}/legacy/hc/prestaciones/`,
      { params: { search }, headers: { regional: this.regional.codigo } });
  }

  getPaises() {
    return this.http.get<Array<Pais>>(
      `${environment.baseUrl}/administracion/paises/`
    );
  }

  getDepartamentos() {
    return this.http.get<Array<Departamento>>(
      `${environment.baseUrl}/administracion/departamentos/`
    );
  }

  getMunicipios(id: string) {
    return this.http.get<Array<Municipio>>(
      `${environment.baseUrl}/administracion/municipios/${id}/`
    );
  }

  getEstadosCiviles() {
    return this.http.get<Array<EstadoCivil>>(
      `${environment.baseUrl}/administracion/estados_civiles/`
    );
  }

  getNivelesInstruccion() {
    return this.http.get<Array<NivelInstruccion>>(
      `${environment.baseUrl}/administracion/niveles_instruccion/`
    );
  }

  getOcupaciones() {
    return this.http.get<Array<Ocupacion>>(
      `${environment.baseUrl}/administracion/ocupaciones/`
    );
  }

  getIdiomas() {
    return this.http.get<Array<Idioma>>(
      `${environment.baseUrl}/administracion/idiomas/`
    );
  }

  getEtnias() {
    return this.http.get<Array<Etnia>>(
      `${environment.baseUrl}/administracion/etnias/`
    );
  }

  getReferencias() {
    return this.http.get<Array<Referencia>>(
      `${environment.baseUrl}/administracion/referencias/`
    );
  }

  getExpedidods() {
    return this.http.get<Array<Expedido>>(
      `${environment.baseUrl}/administracion/expedidos/`
    );
  }

  listPresentacion() {
    return this.http.get<Array<PresentacionFarmaco>>(
      `${environment.baseUrl}/administracion/presentacion/`
    );
  }

  getIdentidadGenero() {
    return this.http.get<Array<IdentidadGenero>>(
      `${environment.baseUrl}/administracion/generos/`
    );
  }

  getOrientacionSexual() {
    return this.http.get<Array<OrientacionSexual>>(
      `${environment.baseUrl}/administracion/orientaciones/`
    );
  }
}
