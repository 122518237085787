import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationComponent } from './modules/core/components/navigation/navigation.component';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { PermissionsGuard } from './modules/core/guards/permissions.guard';
import { HomeComponent } from './modules/core/components/home/home.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';


const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [PermissionsGuard],
    children: [
      {
        path: 'educativo',
        loadChildren: () =>
          import('./modules/educativo/educativo.module')
            .then(m => m.EducativoModule)
      },
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'pacientes',
        loadChildren: () =>
          import('./modules/afiliacion/afiliacion.module')
            .then(m => m.AfiliacionModule)
      },
      {
        path: 'cotizaciones',
        loadChildren: () =>
          import('./modules/cotizaciones/cotizaciones.module')
            .then(m => m.CotizacionesModule)
      },
      {
        path: 'liquidaciones',
        loadChildren: () =>
          import('./modules/liquidaciones/liquidaciones.module')
            .then(m => m.LiquidacionesModule)
      },
      {
        path: 'agenda-medica',
        loadChildren: () =>
          import('./modules/agenda/agenda.module')
            .then(m => m.AgendaModule)
      },
      {
        path: 'facturacion',
        loadChildren: () =>
          import('./modules/facturacion/facturacion.module')
            .then(m => m.FacturacionModule)
      },
      {
        path: 'seguro-delegado',
        loadChildren: () =>
          import('./modules/seguro-delegado/seguro-delegado.module')
            .then(m => m.SeguroDelegadoModule)
      },
      {
        path: 'enfermeria',
        loadChildren: () =>
          import('./modules/enfermeria/enfermeria.module')
            .then(m => m.EnfermeriaModule)
      },
      {
        path: 'expedientes',
        loadChildren: () =>
          import('./modules/consulta-externa/consulta-externa.module')
            .then(m => m.ConsultaExternaModule)
      },
      {
        path: 'cirugia',
        loadChildren: () =>
          import('./modules/cirugia/cirugia.module')
            .then(m => m.CirugiaModule)
      },
      {
        path: 'enfermeria-hospitalizacion',
        loadChildren: () =>
          import('./modules/enfermeria-hospitalizacion/enfermeria-hospitalizacion.module')
            .then(m => m.EnfermeriaHospitalizacionModule)
      },
      {
        path: 'hospitalizacion',
        loadChildren: () =>
          import('./modules/hospitalizacion/hospitalizacion.module')
            .then(m => m.HospitalizacionModule)
      },
      {
        path: 'historial',
        loadChildren: () =>
          import('./modules/historial/historial.module')
            .then(m => m.HistorialModule)
      },
      {
        path: 'crm',
        loadChildren: () =>
          import('./modules/crm/crm.module')
            .then(m => m.CrmModule)
      },
      {
        path: 'examen-complementario',
        loadChildren: () =>
          import('./modules/examen-complementario/examen-complementario.module')
            .then(m => m.ExamenComplementarioModule)
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module')
        .then(m => m.AuthenticationModule)
  },
  {
    path: 'liquidaciones',
    loadChildren: () =>
      import('./modules/liquidaciones/liquidaciones.module')
        .then(m => m.LiquidacionesModule)
  },

  // {
  //   path: 'educativo',
  //   loadChildren: () =>
  //     import('./modules/educativo/educativo.module')
  //       .then(m => m.EducativoModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), NgxMaskModule.forRoot(),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
