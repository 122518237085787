export interface NavigationConfiguration {
  id: number;
  header: string;
  items: Array<NavigationItemConfiguration>;
}

export interface NavigationItemConfiguration {
  title: string;
  route: string;
  icon?: string;
  subnav?: NavigationItemConfiguration;
  permissions?: Array<string>;
}

export const navConfig: Array<NavigationConfiguration> = [
  {
    id: 1,
    header: 'Registro',
    items: [
      {
        title: 'Pacientes',
        route: '/pacientes',
        icon: 'person',
        permissions: ['afiliacion.view_paciente'],
      },
    ],
  },
  // {
  //   id: 2,
  //   header: 'Cotizaciones',
  //   items: [
  //     {
  //       title: 'Cotizar',
  //       route: '/cotizaciones',
  //       icon: 'format_list_numbered',
  //       permissions: ['cotizaciones.view_cotizacion'],
  //     },
  //   ],
  // },
  {
    id: 2,
    header: 'Agenda Medica',
    items: [
      {
        title: 'Configurar',
        route: '/agenda',
        icon: 'event_note',
        permissions: ['agenda.add_agenda'],
      },
      {
        title: 'Registrar',
        route: '/agenda/agendar',
        icon: 'event',
        permissions: ['agenda.add_reserva'],
      },
    ],
  },
  {
    id: 3,
    header: 'Facturacion',
    items: [
      {
        title: 'Factura',
        route: '/facturacion',
        icon: 'receipt',
        permissions: ['facturacion.add_comprobante'],
      },
      // {
      //   title: 'Comprobantes',
      //   route: '/facturacion/comprobante',
      //   icon: 'receipt_long',
      //   permissions: ['facturacion.view_comprobante'],
      // },
      // {
      //   title: 'Mis solicitudes',
      //   route: '/facturacion/solicitud',
      //   icon: 'view_list',
      //   permissions: ['facturacion.view_comprobante'],
      // },
      // {
      //   title: 'Administrar solicitudes',
      //   route: '/facturacion/misolicitud',
      //   icon: 'fact_check',
      //   permissions: ['facturacion.view_solicitud'],
      // },
      // {
      //   title: 'Reportes',
      //   route: '/facturacion/reporte',
      //   icon: 'assessment',
      //   permissions: ['facturacion.view_comprobante'],
      // },
      // {
      //   title: 'Consolidado',
      //   route: '/facturacion/consolidado',
      //   icon: 'assessment',
      //   permissions: ['facturacion.can_view_consolidado',],
      // },
    ],
  },
  // {
  //   id: 4,
  //   header: 'Educativo ',
  //   items: [
  //     {
  //       title: 'General',
  //       route: '/educativo/general/crear-actividad',
  //       icon: 'description',
  //       permissions: ['educativo.add_actividad'],
  //     },
  //     {
  //       title: 'Editar Grupos',
  //       route: '/educativo/grupo',
  //       icon: 'groups',
  //       permissions: ['educativo.view_grupo'],
  //     },
  //     {
  //       title: 'Editar Localidades',
  //       route: '/educativo/municipio',
  //       icon: 'location_city',
  //       permissions: ['educativo.view_municipio'],
  //     },
  //     {
  //       title: 'Editar Proyectos',
  //       route: '/educativo/proyecto',
  //       icon: 'school',
  //       permissions: ['educativo.view_proyecto'],
  //     },
  //     {
  //       title: 'Editar Tipos de actividades',
  //       route: '/educativo/tipo_actividad',
  //       icon: 'confirmation_number',
  //       permissions: ['educativo.view_tipoactividad'],
  //     },
  //     {
  //       title: 'Editar Actividades',
  //       route: '/educativo/actividad',
  //       icon: 'local_activity',
  //       permissions: ['educativo.view_actividad'],
  //     },
  //     {
  //       title: 'Editar Actividades virtuales',
  //       route: '/educativo/actividad_virtual',
  //       icon: 'important_devices',
  //       permissions: ['educativo.view_registroactividadvirtual'],
  //     },
  //     {
  //       title: 'Editar Actividades Informativas',
  //       route: '/educativo/actividad_sala_comunidad',
  //       icon: 'book',
  //       permissions: ['educativo.view_registroactividadesinformativas'],
  //     },
  //     {
  //       title: 'Editar Orientaciones',
  //       route: '/educativo/orientacion',
  //       icon: 'assignment',
  //       permissions: ['educativo.view_orientacion'],
  //     },
  //     {
  //       title: 'Editar Registros de Líderes',
  //       route: '/educativo/lider',
  //       icon: 'school',
  //       permissions: ['educativo.view_lider'],
  //     },
  //     {
  //       title: 'Editar Pre y Post Tests',
  //       route: '/educativo/pre_post_tests',
  //       icon: 'timeline',
  //       permissions: ['educativo.view_preposttest'],
  //     },
  //     {
  //       title: 'Editar Pre y Post Tests de Sensibilización',
  //       route: '/educativo/sens_pre_post_tests',
  //       icon: 'timeline',
  //       permissions: ['educativo.view_preposttest'],
  //     },
  //     {
  //       title: 'Editar Actividades de Jóvenes',
  //       route: '/educativo/kardex_jovenes',
  //       icon: 'description',
  //       permissions: ['educativo.view_kardexjovenes'],
  //     },
  //     {
  //       title: 'Editar Actividades de Profesores',
  //       route: '/educativo/kardex_profesores',
  //       icon: 'description',
  //       permissions: ['educativo.view_kardexprofesores'],
  //     },
  //     {
  //       title: 'Reportes',
  //       route: '/educativo/reportes',
  //       icon: 'description',
  //       permissions: ['educativo.view_actividad'],
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   header: 'Seguros delegados',
  //   items: [
  //     {
  //       title: 'Seguros delegados',
  //       route: '/seguro-delegado',
  //       icon: 'group_work',
  //       permissions: ['seguros_delegados.view_segurodelegado'],
  //     }
  //   ]
  // },
  {
    id: 6,
    header: 'Enfermeria',
    items: [
      {
        title: 'Expedientes',
        route: '/enfermeria',
        icon: 'wc',
        permissions: ['historia_clinica.view_atencion'],
      },
      // {
      //   title: 'Vacunatorio',
      //   route: '/enfermeria/vacunas',
      //   icon: 'vaccines',
      //   permissions: ['historia_clinica.view_vacuna'],
      // }
    ]
  },
  // {
  //   id: 6,
  //   header: 'Examenes',
  //   items: [
  //     {
  //       title: 'Resultados toma de PAP',
  //       route: '/examen-complementario/resultado-pap',
  //       icon: 'oil_barrel',
  //       permissions: ['examen_complementario.view_resultadopapsinresultado'],
  //     },
  //     {
  //       title: 'Reporte de PAP',
  //       route: '/examen-complementario/reporte-resultado-pap',
  //       icon: 'assessment',
  //       permissions: ['examen_complementario.view_resultadopap'],
  //     },
  //     {
  //       title: 'Resultados captura hibrida',
  //       route: '/examen-complementario/resultado-captura-hibrida',
  //       icon: 'battery_3_bar',
  //       permissions: ['examen_complementario.view_resultadocapturahibridaresultado'],
  //     },
  //     {
  //       title: 'Reporte de Captura Hibrida',
  //       route: '/examen-complementario/reporte-resultado-captura-hibrida',
  //       icon: 'assessment',
  //       permissions: ['examen_complementario.view_resultadocapturahibridaresultado'],
  //     }
  //   ]
  // },
  {
    id: 7,
    header: 'Consulta Medica',
    items: [
      {
        title: 'Atención',
        route: '/expedientes/espera',
        icon: 'wc',
        permissions: ['historia_clinica.change_historiaclinica'],
      },
      {
        title: 'Atendidos',
        route: '/expedientes/atendido',
        icon: 'assignment_ind',
        permissions: ['historia_clinica.change_historiaclinica'],
      },
      {
        title: 'Reagendamiento',
        route: '/expedientes/reagendamiento',
        icon: 'event',
        permissions: ['historia_clinica.change_historiaclinica'],
      }
    ]
  },
  // {
  //   id: 8,
  //   header: 'Cirugia',
  //   items: [
  //     {
  //       title: 'Solicitudes quirofano',
  //       route: '/cirugia',
  //       icon: 'fact_check',
  //       permissions: ['cirugia.view_cirugia'],
  //     },
  //     {
  //       title: 'Seguimiento de Pacientes',
  //       route: '/cirugia/lista-seguimiento-pacientes',
  //       icon: 'wc',
  //       permissions: ['cirugia.view_cirugia'],
  //     }]
  // },
  // {
  //   id: 9,
  //   header: 'Enfermeria hospitalización',
  //   items: [
  //     {
  //       title: 'Seguimiento enfermeria',
  //       route: '/enfermeria-hospitalizacion/pacientes-hospitalizados',
  //       icon: 'wc',
  //       permissions: ['hospitalizacion.view_hospitalizacion', 'enfermeria_hospitalizacion.view_notaenfermeria'],
  //     }]
  // },
  // {
  //   id: 10,
  //   header: 'Hospitalización',
  //   items: [
  //     {
  //       title: 'Admisión paciente',
  //       route: '/enfermeria-hospitalizacion',
  //       icon: 'fact_check',
  //       permissions: ['hospitalizacion.add_hospitalizacion'],
  //     },
  //     {
  //       title: 'Seguimiento de pacientes',
  //       route: '/hospitalizacion/pacientes-hospitalizados',
  //       icon: 'wc',
  //       permissions: ['hospitalizacion.view_hospitalizacion'],
  //     }]
  // },
  {
    id: 11,
    header: 'Historial',
    items: [
      {
        title: 'Historial paciente',
        route: '/historial',
        icon: 'history',
        permissions: ['afiliacion.view_paciente'],
      },
    ]
  },
];
