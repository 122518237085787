import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../../authentication/services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { ApiService as AdminApiService } from '../../../administracion/services/api.service';
import { Expedido } from 'src/app/modules/administracion/models/configuracion';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PerfilUsuarioMedicare } from '../../models/perfil_usuario_medicare';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss']
})
export class PerfilUsuarioComponent implements OnInit {
  perfilForm: UntypedFormGroup;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  mensaje: String = '';
  mensajeImagen: String = '';
  expedidos: Array<Expedido>;
  successSave: EventEmitter<any> = new EventEmitter<any>(null);
  loading: Boolean = true;
  todaydate: Date = new Date();
  seCambioFoto: Boolean = true;
  seCambioFirma: Boolean = true;
  seCambioSello: Boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PerfilUsuarioMedicare>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private adminApi: AdminApiService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.adminApi.getExpedidods().subscribe(result => this.expedidos = result);
    this.perfilForm = this.fb.group({
      id: new UntypedFormControl(null, Validators.required),
      matricula: new UntypedFormControl(null, Validators.required),
      foto: new UntypedFormControl(null, Validators.required),
      firma: new UntypedFormControl(null, Validators.required),
      sello: new UntypedFormControl(null),
      persona: this.fb.group({
        id: new UntypedFormControl(null, Validators.required),
        nombres: new UntypedFormControl(null, Validators.required),
        paterno: new UntypedFormControl(null, Validators.required),
        materno: new UntypedFormControl(null, Validators.required),
        fecha_nacimiento: new UntypedFormControl(null, Validators.required),
        documento: new UntypedFormControl(null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(8)]),
        tipo_documento: new UntypedFormControl('CIN', Validators.required),
        expedido: new UntypedFormControl(null, Validators.required),
        sexo: new UntypedFormControl(null),
        domicilio: new UntypedFormControl(null),
        extension_documento: new UntypedFormControl(null),
      }),
    });

    this.auth.currentUser().subscribe(result => {
      if (result != null) {
        this.perfilForm.controls.id.setValue(result.medico_id);
        this.api.searchPerfilUsuario(result.usuario).subscribe(perfil => {
          if (perfil.length > 0) {
            this.cargarValores(perfil[0]);
          }
        });
        this.api.informacionMedico(result.medico_id).subscribe(result => {
          this.cargarValoresMedico(result);
        });
      }
    });
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
  }

  onChangeFoto(event) {
    if (event.target.files.length > 0) {
      this.seCambioFoto = false;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.mensajeImagen = 'La imagen debe ser menor a 1MB ';
        this.f.foto.setValue(null);
      } else {
        this.mensajeImagen = '';
        this.f.foto.setValue(file);
      }
    }
  }

  onChangeFirma(event) {
    if (event.target.files.length > 0) {
      this.seCambioFirma = false;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.mensajeImagen = 'La imagen debe ser menor a 1MB ';
        this.f.firma.setValue(file);
      } else {
        this.mensajeImagen = '';
        this.f.firma.setValue(file);
      }
    }
  }

  onChangeSello(event) {
    if (event.target.files.length > 0) {
      this.seCambioSello = false;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.mensajeImagen = 'La imagen debe ser menor a 1MB ';
        this.f.sello.setValue(file);
      } else {
        this.mensajeImagen = '';
        this.f.sello.setValue(file);
      }
    }
  }

  get f() {
    return this.perfilForm.controls;
  }

  get p() {
    return (this.perfilForm.controls.persona as UntypedFormGroup).controls;
  }

  cargarValores(valores: any) {
    this.f.persona.patchValue(valores.persona);
    if (valores.persona.fecha_nacimiento != null) {
      const partes = valores.persona.fecha_nacimiento.split('-');
      const anio = Number(partes[0]);
      const mes = Number(partes[1]) - 1;
      const dia = Number(partes[2]);
      this.p.fecha_nacimiento.setValue(new Date(anio, mes, dia));
    }
    this.perfilForm.updateValueAndValidity();
  }

  cargarValoresMedico(valores: any) {
    this.f.id.setValue(valores.id);
    this.f.matricula.setValue(valores.matricula);
    this.f.foto.setValue(valores.foto);
    this.f.firma.setValue(valores.firma);
    this.f.sello.setValue(valores.sello);
    this.perfilForm.updateValueAndValidity();
  }

  onNoClick(): void {
    this.data = false;
    this.dialogRef.close(false);
  }

  guardarPerfilUsuario(): void {
    this.mensaje = "";
    this.loading = false;
    if (this.perfilForm.valid) {
      const fakeFile = new Blob(['Contenido de prueba'], { type: 'text/plain' });
      const fakeFileName = 'fake-file.txt';
      if (this.seCambioFirma) {
        this.f.firma.setValue(null);
      }
      if (this.seCambioFoto) {
        this.f.foto.setValue(null);
      }
      if (this.seCambioSello) {
        this.f.sello.setValue(null);
      }
      let datos: any = this.perfilForm.value;
      let fc = moment(this.perfilForm.value.persona.fecha_nacimiento).format('YYYY-MM-DD');
      datos.persona.fecha_nacimiento = fc;
      const formData = new FormData();
      formData.append('id', datos.id);
      formData.append('matricula', datos.matricula);
      formData.append('foto', datos.foto);
      formData.append('firma', datos.firma);
      formData.append('sello', datos.sello);
      formData.append('nombres', datos.persona.nombres);
      formData.append('paterno', datos.persona.paterno);
      formData.append('materno', datos.persona.materno);
      formData.append('sexo', datos.persona.sexo);
      formData.append('fecha_nacimiento', datos.persona.fecha_nacimiento);
      formData.append('documento', datos.persona.documento);
      formData.append('expedido', datos.persona.expedido);
      formData.append('domicilio', datos.persona.domicilio);
      formData.append('persona', datos.persona.id);

      this.api.guardarPerfilUsuarioMedico(this.f.id.value, formData)
        .subscribe(result => this.done(result), error => this.fail(error));
    } else {
      this.loading = true;
    }
  }

  private done(data: any) {
    this.snackbar.open('Contraseña guardada correctamente', null, { duration: 1500 });
    this.dialogRef.close(true);
    this.successSave.emit(true);
    this.loading = false;
    this.auth.logout();
    this.router.navigate(['']);
    this.router.navigate(['/auth/login']);
  }

  private fail(error: any) {
    this.loading = true;
    this.mensaje = error.error
  }
}
