import { Injectable } from '@angular/core';
import { MatSidenavContainer } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  private _sidenav: MatSidenavContainer

  constructor() { }

  set sidenav(sidenav: MatSidenavContainer) {
    this._sidenav = sidenav;
  }

  get sidenav(): MatSidenavContainer { return this._sidenav}

  scrollTop(): void{
    this.sidenav._content._container.scrollable.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  scrollBottom(): void{
    this.sidenav._content._container.scrollable.scrollTo({
      top: this.sidenav._content.getElementRef().nativeElement.scrollHeight,
      behavior: 'smooth'
    })
  }
}
