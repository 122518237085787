import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../authentication/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard  {

  constructor(private router: Router,
    private auth: AuthService,
  ){}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.auth.currentUser().pipe(
        map(user => {
            if (user) {
              if (user.superusuario) return true;
              if (next.data.permission && !user.permisos.includes(next.data.permission)) {
                this.router.navigate(['/auth/login'], { state: { reason: 'forbidden' }});
                return false;
              }
              return true;
            }
            this.router.navigate(['/auth/login'], { state: { reason: 'no_logged' }});
            return false;
        })
      );
  }
  
}
