import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InformacionPaciente } from '../../afiliacion/models/paciente';
import { Cie, DiagnosticoOms, ResultadoCie } from '../models/cie';
import { Pagination } from '../models/pagination';
import { of, Observable } from 'rxjs';
import { PerfilMedico, PerfilUsuario } from '../models/usuario';
import { AuthService } from '../../authentication/services/auth.service';
import { Regional } from '../../administracion/models/regional';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  regional: Regional;
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.auth.currentRegional().subscribe(regional => this.regional = regional);
  }

  informacionPaciente(expedienteClinicoId: Number) {
    return this.http.get<InformacionPaciente>(`${environment.baseUrl}/afiliacion/pacientes/${expedienteClinicoId}/informacion/`);
  }
  searchCie(search: string) {
    return this.http.get<ResultadoCie>(`${environment.baseUrl}/core/cie/`,
      { params: { q: search } });
  }
  searchDiagnosticoOms(search: string) {
    return this.http.get<Pagination<DiagnosticoOms>>(
      `${environment.baseUrl}/core/diagnosticos/`,
      {
        params: {
          search: search
        }
      }
    );
  }

  searchPerfilUsuario(search: string): Observable<Array<PerfilUsuario>> {
    return this.http.get<Array<PerfilUsuario>>(`${environment.baseUrl}/afiliacion/perfil/`,
      { params: { search } });
  }

  guardarPerfilUsuario(usuarioId: Number, perfilUsuario: any) {
    return this.http.patch<Number>(`${environment.baseUrl}/afiliacion/perfil/${usuarioId}/`, perfilUsuario);
  }

  guardarPerfilUsuarioMedico(medicoId: Number, perfilUsuario: any) {
    return this.http.patch<any>(`${environment.baseUrl}/afiliacion/perfilmedico/`, perfilUsuario);
  }

  informacionMedico(medicoId: Number) {
    return this.http.get<PerfilMedico>(`${environment.baseUrl}/afiliacion/perfil/${medicoId}/medico/`);
  }

  tiempoEsperaAtencion(medicoId: Number) {
    return this.http.get<any>(`${environment.baseUrl}/core/atenciones/${medicoId}/tiempo_espera_atencion/`,
      { headers: { regional: this.regional.codigo } }
    );
  }

  frecuenciaAtencionesPaciente(medicoId: Number) {
    return this.http.get<any>(`${environment.baseUrl}/core/atenciones/${medicoId}/frecuencia_atenciones_paciente/`,
      { headers: { regional: this.regional.codigo } }
    );
  }

  frecuenciaAtencionesAtendidas(medicoId: Number) {
    return this.http.get<any>(`${environment.baseUrl}/core/atenciones/${medicoId}/atenciones_atendidas/`,
      { headers: { regional: this.regional.codigo } }
    );
  }

  frecuenciaTurnosLibresVsReservados(medicoId: Number) {
    return this.http.get<any>(`${environment.baseUrl}/core/atenciones/${medicoId}/turnos_libres_vs_turnos_ocupados/`,
      { headers: { regional: this.regional.codigo } }
    );
  }
}
