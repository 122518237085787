import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { ScrollService } from '../../services/scroll.service';
import { AuthService } from '../../../authentication/services/auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements AfterViewInit {

  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(
      map(
        result => result.matches
      ),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private scroll: ScrollService,
    public auth: AuthService,
  ) { }

  ngAfterViewInit(): void {
    this.scroll.sidenav = this.sidenavContainer;
  }

}
