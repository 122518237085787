<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="center center" class="mat-elevation-z0">
  <button type="button" fxFlex="none" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
    *ngIf="isHandset$ | async">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span fxFlex="none">HIS</span>
  <span fxFlex></span>
  <!-- <a mat-icon-button (click)="openDialogPerfilUsuario()">
    <mat-icon>account_circle</mat-icon>
  </a>
  <a mat-icon-button (click)="openDialogResultado()">
    <mat-icon>lock</mat-icon>
  </a> -->
  <button mat-icon-button fxFlex="nogrow" (click)="logout()">
    <mat-icon aria-hidden="false">exit_to_app</mat-icon>
  </button>
</mat-toolbar>