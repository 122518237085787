<form [formGroup]="perfilForm">
    <div mat-dialog-title fxLayoutAlign="center center">
        Actualizar datos de usuario médico
    </div>
    <div mat-dialog-content>
        <div fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="16px" fxLayoutGap.lt-sm="8px">

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap="3%">
                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Nombres</mat-label>
                        <input matInput formControlName="nombres" autocomplete="off" >
                        <mat-error *ngIf="p.nombres.hasError('required')">
                            El campo Nombres es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Primer apellido</mat-label>
                        <input matInput formControlName="paterno" autocomplete="off">
                        <mat-error *ngIf="p.paterno.hasError('required')">
                            El campo Primer apellido es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Segundo apellido</mat-label>
                        <input matInput formControlName="materno" autocomplete="off">
                        <mat-error *ngIf="p.materno.hasError('required')">
                            El campo Primer apellido es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Sexo</mat-label>
                        <mat-select formControlName="sexo" required>
                            <mat-option value='MA'>Masculino</mat-option>
                            <mat-option value='FE'>Femenino</mat-option>
                        </mat-select>
                        <mat-error *ngIf="p.sexo.hasError('required')">
                            El sexo es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap="3%">

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Fecha de nacimiento</mat-label>
                        <input matInput [matDatepicker]="picker" required readonly formControlName="fecha_nacimiento"
                            (dateChange)="onDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker [touchUi]="isHandset$ | async">
                        </mat-datepicker>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Documento</mat-label>
                        <input type="text" matInput formControlName="documento" required>
                        <mat-error *ngIf="p.documento.hasError('required')">
                            El numero de documento es obligatorio
                        </mat-error>
                        <mat-error *ngIf="p.documento.hasError('maxlength')">
                            El documento no puede tener mas de 8 digitos
                        </mat-error>
                        <mat-error *ngIf="p.documento.hasError('pattern')">
                            El documento debe ser un numero
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Expedido</mat-label>
                        <mat-select formControlName="expedido" required>
                            <mat-option *ngFor="let expedido of expedidos" [value]="expedido.id">
                                {{expedido.nombre}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="p.expedido.hasError('required')">
                            El lugar de expedicion es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <div fxFlex fxFill></div>
                </div>

                <mat-divider class="divider"></mat-divider>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap="3%">
                    <mat-form-field fxFlex fxFill>
                        <mat-label>Matricula</mat-label>
                        <input matInput formControlName="matricula" autocomplete="off" accept=".jpg,.png,.jpeg" >
                        <mat-error *ngIf="f.matricula.hasError('required')">
                            El campo Matricula es obligatorio
                        </mat-error>
                    </mat-form-field>
                    <div fxFlex fxFill fxLayout="column" fxLayoutAlign="space-evenly start">
                        <mat-label class="titulo">Foto</mat-label>
                        <input class="custom-file-input tituloboton" type="file" name="foto"
                            (change)="onChangeFoto($event)" accept=".jpg,.png,.jpeg" />
                        <mat-label *ngIf="seCambioFoto" class="titulo">{{f.foto.value}}</mat-label>
                        <mat-error *ngIf="f.foto.hasError('required')">
                            El campo foto es obligatorio
                        </mat-error>
                    </div>
                    <div fxFlex fxFill fxLayout="column" fxLayoutAlign="space-evenly start">
                        <mat-label class="titulo">Firma</mat-label>
                        <input class="custom-file-input tituloboton" type="file" name="firma"
                            (change)="onChangeFirma($event)" accept=".jpg,.png,.jpeg"/>
                        <mat-label *ngIf="seCambioFirma" class="titulo">{{f.firma.value}}</mat-label>
                        <mat-error *ngIf="f.firma.hasError('required')">
                            El campo firma es obligatorio
                        </mat-error>
                    </div>
                    <div fxFlex fxFill fxLayout="column" fxLayoutAlign="space-evenly start">
                        <mat-label class="titulo">Sello</mat-label>
                        <input class="custom-file-input tituloboton" type="file" name="sello"
                            (change)="onChangeSello($event)" />
                        <mat-label *ngIf="seCambioSello" class="titulo">{{f.sello.value}}</mat-label>
                        <mat-error *ngIf="f.sello.hasError('required')">
                            El campo sello es obligatorio
                        </mat-error>
                    </div>
                </div>

            </div>
            <mat-error>
                {{mensajeImagen}}
                {{mensaje}}
            </mat-error>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="center center">
        <button mat-button color="accent" type="submit" *ngIf="loading" (click)="onNoClick()">Cancelar</button>
        <button mat-button color="primary" *ngIf="loading"
            (click)="guardarPerfilUsuario()"><span>Guardar</span></button>
    </div>
</form>