<form [formGroup]="perfilForm">
    <div mat-dialog-title fxLayoutAlign="center center">
        Cambiar contraseña
    </div>
    <div mat-dialog-content>
        <div fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="16px" fxLayoutGap.lt-sm="8px">
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap="3%">
                    <mat-form-field fxFlex fxFill>
                        <mat-label>Contraseña actual</mat-label>
                        <input type="password" matInput formControlName="password" autocomplete="off">
                        <mat-error *ngIf="f.password.hasError('required')">
                            El campo Nombres es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex fxFill>
                        <mat-label>Contraseña nueva</mat-label>
                        <input type="password" matInput formControlName="nuevo_password" autocomplete="off">
                        <mat-error *ngIf="f.nuevo_password.hasError('required')">
                            El campo Nombres es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex fxFill>
                        <mat-label>Repita la contraseña nueva</mat-label>
                        <input type="password" matInput formControlName="nuevo_password_repetido" autocomplete="off">
                        <mat-error *ngIf="f.nuevo_password_repetido.hasError('required')">
                            El campo Nombres es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-divider class="divider"></mat-divider>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap="3%">
                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Nombres</mat-label>
                        <input matInput formControlName="nombres" autocomplete="off">
                        <mat-error *ngIf="p.nombres.hasError('required')">
                            El campo Nombres es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Primer apellido</mat-label>
                        <input matInput formControlName="paterno" autocomplete="off">
                        <mat-error *ngIf="p.paterno.hasError('required')">
                            El campo Primer apellido es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Segundo apellido</mat-label>
                        <input matInput formControlName="materno" autocomplete="off">
                        <mat-error *ngIf="p.materno.hasError('required')">
                            El campo Primer apellido es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap="3%">
                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Sexo</mat-label>
                        <mat-select formControlName="sexo" required>
                            <mat-option value='MA'>Masculino</mat-option>
                            <mat-option value='FE'>Femenino</mat-option>
                        </mat-select>
                        <mat-error *ngIf="p.sexo.hasError('required')">
                            El sexo es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Fecha de nacimiento</mat-label>
                        <input matInput [matDatepicker]="picker" required readonly formControlName="fecha_nacimiento" (dateChange)="onDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker [touchUi]="isHandset$ | async">
                        </mat-datepicker>
                    </mat-form-field>

                    <div fxFlex fxFill></div>
                </div>

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" fxLayoutGap="3%">
                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Documento</mat-label>
                        <input type="text" matInput formControlName="documento" required>
                        <mat-error *ngIf="p.documento.hasError('required')">
                            El numero de documento es obligatorio
                        </mat-error>
                        <mat-error *ngIf="p.documento.hasError('maxlength')">
                            El documento no puede tener mas de 8 digitos
                        </mat-error>
                        <mat-error *ngIf="p.documento.hasError('pattern')">
                            El documento debe ser un numero
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field formGroupName="persona" fxFlex fxFill>
                        <mat-label>Expedido</mat-label>
                        <mat-select formControlName="expedido" required>
                            <mat-option *ngFor="let expedido of expedidos" [value]="expedido.id">
                                {{expedido.nombre}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="p.expedido.hasError('required')">
                            El lugar de expedicion es obligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex fxFill>
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" required>
                        <mat-error *ngIf="f.email.hasError('required')">
                            El email es obligatorio
                        </mat-error>
                        <mat-error *ngIf="f.email.hasError('email')">
                            El email no tiene un formato valido
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-error>
                {{mensaje}}
            </mat-error>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="center center">
        <button mat-button color="accent" type="submit" *ngIf="loading" (click)="onNoClick()">Cancelar</button>
        <button mat-button color="primary" *ngIf="loading" (click)="guardarPerfilUsuario()"><span>Guardar</span></button>
    </div>
</form>