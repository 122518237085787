import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {AuthService} from '../../authentication/services/auth.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router: Router, private auth: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> {
      return this.auth.currentUser().pipe(
        map(usuario => {
            if (usuario) {
              return true;
            } else {
              this.router.navigate(['/auth/login'],
                { queryParams: { returnUrl: state.url }, state: { reason: 'no_logged'}});
              return false;
            }
          }
        )
      );
  }
}
