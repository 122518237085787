import { Component, OnInit, Input } from '@angular/core';
import { matExpansionAnimations, MatExpansionPanelState } from '@angular/material/expansion';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { NavigationConfiguration } from '../../config/navigation.config';

@Component({
  selector: 'app-sidemenu-item',
  templateUrl: './sidemenu-item.component.html',
  styleUrls: ['./sidemenu-item.component.scss'],
  animations: [matExpansionAnimations.bodyExpansion, matExpansionAnimations.indicatorRotate],
})
export class SidemenuItemComponent extends CdkAccordionItem implements OnInit {

  @Input() nav: NavigationConfiguration;

  ngOnInit(): void { }

  getExpandedState(): MatExpansionPanelState {
    return this.expanded ? 'collapsed' : 'expanded';
  }

}
