import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatTreeModule } from '@angular/material/tree';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { SidemenuItemComponent } from './components/sidemenu-item/sidemenu-item.component';
import { MainToolbarComponent } from './components/main-toolbar/main-toolbar.component';
import { SidemenuToolbarComponent } from './components/sidemenu-toolbar/sidemenu-toolbar.component';
import { HomeComponent } from './components/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { ActionFabComponent } from './components/action-fab/action-fab.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectEspecialidadComponent } from './components/select-especialidad/select-especialidad.component';
import { SelectRegionalComponent } from './components/select-regional/select-regional.component';
import { DialogConfirmacionGenericoComponent } from './components/dialog-confirmacion-generico/dialog-confirmacion-generico.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { UltimaTomaSignosvitalesComponent } from './components/ultima-toma-signosvitales/ultima-toma-signosvitales.component';
import { InformacionPacienteComponent } from './components/informacion-paciente/informacion-paciente.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { InformacionBasicaPacienteComponent } from './components/informacion-basica-paciente/informacion-basica-paciente.component';
import { SearchCie11Component } from './components/search-cie11/search-cie11.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { SearchDiagnosticosOmsComponent } from "./components/search-diagnosticos-oms/search-diagnosticos-oms.component";
import { SelectPresentacionFarmacoComponent } from './components/select-presentacion-farmaco/select-presentacion-farmaco.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { DialogConfirmarPasswordComponent } from './components/dialog-confirmar-password/dialog-confirmar-password.component';
import { PerfilUsuarioComponent } from './components/perfil-usuario/perfil-usuario.component';

@NgModule({
  declarations: [NavigationComponent,
    SelectEspecialidadComponent,
    SidemenuComponent, SidemenuItemComponent,
    MainToolbarComponent,
    SidemenuToolbarComponent,
    HomeComponent,
    ActionFabComponent,
    MessagesComponent,
    SelectRegionalComponent,
    DialogConfirmacionGenericoComponent,
    NavigationComponent,
    SelectEspecialidadComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    MainToolbarComponent,
    SidemenuToolbarComponent,
    HomeComponent,
    ActionFabComponent,
    MessagesComponent,
    SelectRegionalComponent,
    DialogConfirmacionGenericoComponent,
    UltimaTomaSignosvitalesComponent,
    InformacionPacienteComponent,
    InformacionBasicaPacienteComponent,
    SearchCie11Component,
    SearchDiagnosticosOmsComponent,
    SelectPresentacionFarmacoComponent,
    PerfilComponent,
    DialogConfirmarPasswordComponent,
    PerfilUsuarioComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    FlexLayoutModule,
    CdkAccordionModule,
    CdkScrollableModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MatCheckboxModule, CommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    HttpClientModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTreeModule,
    MatCheckboxModule
  ],
  exports: [
    NavigationComponent,
    ActionFabComponent,
    SelectEspecialidadComponent,
    SelectRegionalComponent,
    NavigationComponent,
    ActionFabComponent,
    SelectEspecialidadComponent,
    SelectRegionalComponent,
    UltimaTomaSignosvitalesComponent,
    InformacionPacienteComponent,
    InformacionBasicaPacienteComponent,
    SearchCie11Component,
    SearchDiagnosticosOmsComponent,
  ]

})
export class CoreModule { }
