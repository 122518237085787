import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../../core/models/usuario';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login';
import { map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { Token } from '../models/token';
import { Regional } from '../../administracion/models/regional';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  usuario: BehaviorSubject<Usuario> = new BehaviorSubject<Usuario>(null);
  regional: BehaviorSubject<Regional> = new BehaviorSubject<Regional>(null);

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
  ) { }

  login(login: Login): Observable<Usuario> {
    return this.http.post<Usuario>(`${environment.baseUrl}/auth/login/`, login)
      .pipe(
        tap(usuario => {
          this.setUsuario('currentUser', usuario);
        }
        )
      );
  }

  logout(): void {
    this.sessionStorage.removeItem('currentUser');
    this.sessionStorage.removeItem('currentRegional');
    this.sessionStorage.clear();
    this.usuario.next(null);
    this.sessionStorage.setItem('currentUser', null);
    this.sessionStorage.setItem('currentRegional', null);
  }

  currentUser(): Observable<Usuario> {
    return this.usuario.pipe(
      switchMap(usuario => {
        if (usuario) {
          return of(usuario);
        }
        const sessionUser = this.sessionStorage.getItem('currentUser') as Usuario;
        if (sessionUser) {
          return of(sessionUser);
        }
        return of(null);
      })
    );
  }

  currentRegional(): Observable<Regional> {
    return this.regional.pipe(
      switchMap(regional => {
        if (regional) {
          return of(regional);
        }
        const sessionRegional = this.sessionStorage.getItem('currentRegional') as Regional;
        if (sessionRegional) {
          return of(sessionRegional);
        }
        return of(null);
      })
    );
  }

  refreshToken(): Observable<Token> {
    const user = this.sessionStorage.getItem('currentUser') as Usuario;
    const refreshToken = user.token;
    return this.http.post<Token>(
      `${environment.baseUrl}/auth/login/refresh/`,
      { refresh: refreshToken.refresh }
    ).pipe(
      tap(response => {
        this.setToken(response);
      })
    );
  }

  private setUsuario(key: string, usuario: Usuario): void {
    this.sessionStorage.setItem(key, usuario);
  }

  private setToken(token: Token): void {
    this.currentUser().subscribe(usuario => {
      if (usuario) {
        usuario.token.access = token.access;
        this.setUsuario('currentUser', usuario);
      }
    });
  }

  public setRegional(key: string, regional: Regional): void {
    this.sessionStorage.setItem(key, regional);
    this.regional.next(regional);
  }

  public checkPermission(permission: string): Observable<boolean> {
    return this.currentUser().pipe(
      map(user => {
        if (user) {
          return user.permisos.includes(permission);
        }
        return false;
      })
    )
  }

  recuperarPassword(login: Login): Observable<Usuario> {
    return this.http.post<Usuario>(`${environment.baseUrl}/auth/recuperar-password/`, login)
  }
}