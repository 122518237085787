import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {SessionStorageService} from '../../core/services/session-storage.service';
import {Router} from '@angular/router';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Usuario} from '../../core/models/usuario';
import {environment} from '../../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private auth: AuthService,
    private sessionStorage: SessionStorageService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const usuario = this.sessionStorage.getItem('currentUser') as Usuario;
    if (usuario){
      const token = usuario.token;
      return next.handle(this.addAuthorizationHeader(request, token.access)).pipe(
        catchError(error => {
          if (request.url === `${environment.baseUrl}/auth/login/refresh/`) {
            this.refreshingInProgress = false;
            return this.logoutAndRedirect(error);
          }
          if (error instanceof HttpErrorResponse && error.status === 401) {
            if (token.refresh && token.access) {
              return this.refreshToken(request, next);
            }
            return this.logoutAndRedirect(error);
          }

          if (error instanceof HttpErrorResponse && error.status === 403) {
            return this.logoutAndRedirect(error, 'forbidden');
          }

          return throwError(error);
        }),
      );
    }
    return next.handle(request);
  }

  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
    }
    return request;
  }

  private logoutAndRedirect(err: any, reason: string = 'session_expired'): Observable<HttpEvent<any>> {
    this.auth.logout();
    this.router.navigate(['/auth/login'], {state: { reason: reason}});
    return throwError(err);
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);

      return this.auth.refreshToken().pipe(
        switchMap(response => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(response.access);
          return next.handle(this.addAuthorizationHeader(request, response.access));
        })
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAuthorizationHeader(request, token));
        }));
    }
  }
}
