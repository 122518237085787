import { Component, OnInit, Inject, ViewChildren, QueryList, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../../../authentication/services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { ApiService as AdminApiService } from '../../../administracion/services/api.service';
import { Expedido } from 'src/app/modules/administracion/models/configuracion';
import { PerfilUsuario } from '../../models/usuario';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  perfilForm: UntypedFormGroup;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  mensaje: String = '';
  expedidos: Array<Expedido>;
  successSave: EventEmitter<any> = new EventEmitter<any>(null);
  loading: Boolean = true;
  todaydate: Date = new Date();

  constructor(
    public dialogRef: MatDialogRef<PerfilComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private adminApi: AdminApiService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.adminApi.getExpedidods().subscribe(result => this.expedidos = result);
    this.perfilForm = this.fb.group({
      persona: this.fb.group({
        nombres: new UntypedFormControl(null, Validators.required),
        paterno: new UntypedFormControl(null, Validators.required),
        materno: new UntypedFormControl(null, Validators.required),
        fecha_nacimiento: new UntypedFormControl(null, Validators.required),
        documento: new UntypedFormControl(null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(8)]),
        tipo_documento: new UntypedFormControl('CIN', Validators.required),
        expedido: new UntypedFormControl(null, Validators.required),
        sexo: new UntypedFormControl(null),
        domicilio: new UntypedFormControl(null),
        extension_documento: new UntypedFormControl(null),
      }),
      id: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
      nuevo_password: new UntypedFormControl(null, Validators.required),
      nuevo_password_repetido: new UntypedFormControl(null, Validators.required),
      username: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
    });

    this.auth.currentUser().subscribe(result => {
      if (result != null) {
        this.api.searchPerfilUsuario(result.usuario).subscribe(perfil => {
          if (perfil.length > 0) {
            this.cargarValores(perfil[0]);
          }
        });
      }
    });
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
/*     const selectedDate = event.value;
    this.p.fecha_nacimiento.setValue(selectedDate);
    this.perfilForm.updateValueAndValidity(); */
  }

  get f() {
    return this.perfilForm.controls;
  }

  get p() {
    return (this.perfilForm.controls.persona as UntypedFormGroup).controls;
  }

  cargarValores(valores: any) {
    //this.perfilForm.patchValue(valores);
    this.f.password.setValue(null);
    this.f.nuevo_password.setValue(null);
    this.f.nuevo_password_repetido.setValue(null);
    this.f.id.setValue(valores.id);
    this.f.username.setValue(valores.username);
    this.f.persona.patchValue(valores.persona);
    this.f.email.setValue(valores.email);
    if(valores.persona.fecha_nacimiento != null){
      const partes = valores.persona.fecha_nacimiento.split('-');
      const anio = Number(partes[0]);
      const mes = Number(partes[1]) - 1; 
      const dia = Number(partes[2]);
      this.p.fecha_nacimiento.setValue(new Date(anio, mes, dia));
    }
    this.perfilForm.updateValueAndValidity();
  }

  onNoClick(): void {
    this.data = false;
    this.dialogRef.close(false);
  }

  guardarPerfilUsuario(): void {
    this.mensaje = "";
    this.loading = false;
    if (this.perfilForm.valid) {
      let datos :any= this.perfilForm.value;
      let fc = moment(this.perfilForm.value.persona.fecha_nacimiento).format('YYYY-MM-DD');
      datos.persona.fecha_nacimiento = fc;
      this.api.guardarPerfilUsuario(this.f.id.value, datos)
        .subscribe(result => this.done(result), error => this.fail(error));
    } else {
      this.loading = true;
    }
  }

  private done(data: any) {
    this.snackbar.open('Contraseña guardada correctamente', null, { duration: 1500 });
    this.dialogRef.close(true);
    this.successSave.emit(true);
    this.loading = false;
    this.auth.logout();
    this.router.navigate(['']);
    this.router.navigate(['/auth/login']);
  }

  private fail(error: any) {
    this.loading = true;
    this.mensaje = error.error
  }
}
