<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="center center" class="mat-elevation-z0 toolbar">
    <div fxLayout="column" fxLayoutAlign="center center" class="container" *ngIf="usuario">
        <img fxFlex [src]="environment.baseUrl + usuario.foto" alt="" *ngIf="usuario.foto; else nophoto">
        <span class="mat-title" fxFlex fxFill>{{ usuario.nombres }} {{ usuario.paterno }}</span>
        <mat-form-field fxFlex fxFill>
            <mat-label>Sucursal</mat-label>
            <mat-select [formControl]="regionalControl" (selectionChange)="selection($event)" [compareWith]="compare">
                <mat-option *ngFor="let regional of usuario.regionales" [value]="regional">
                    {{regional.nombre}}
                </mat-option>
                <!-- <mat-option>Vacio</mat-option> -->
            </mat-select>
        </mat-form-field>
        <!-- <div class="mensajereg" *ngIf="!regionalSeleccionada">
            <mat-label>
                Por favor selecciona una sucursal.
            </mat-label>
        </div> -->
    </div>
</mat-toolbar>
<ng-template #nophoto>
    <img fxFlex src="/assets/images/avatars/users/avatar-hombre.png" alt="" *ngIf="usuario.sexo === 'MA'">
    <img fxFlex src="/assets/images/avatars/users/avatar-mujer.png" alt="" *ngIf="usuario.sexo === 'FE'">
</ng-template>