<div fxFlex fxFill fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="16px" fxLayoutGap.lt-sm="8px">
    <!-- <div>
        <h1>Bienvenido a CMM Integral</h1>
    </div> -->
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div class="mat-elevation-z4">
            <div [style.display]="tiempoEspera">
                <div id="chart" style="height: 330px; width: 510px;"></div>
            </div>
        </div>
        <div class="mat-elevation-z4">
            <div [style.display]="frecuenciaAtenciones">
                <div id="chart2" style="height: 330px;  width: 510px;"></div>
            </div>
        </div>
        <div class="mat-elevation-z4">
            <div [style.display]="atenciones">
                <div id="chart3" style="height: 330px;  width: 235px;"></div>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z4">
        <div [style.display]="turnosvs">
            <div style="height: 255px;" id="chart4"></div>
        </div>
    </div>
</div>