<mat-sidenav-container class="sidenav-container" fxFlexFill class="mat-elevation-z0">
    <mat-sidenav fxFlexFill #drawer [fxFlex]="(isHandset$ | async) ? 60 : 15" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <app-sidemenu-toolbar></app-sidemenu-toolbar>
        <mat-divider></mat-divider>
        <app-sidemenu></app-sidemenu>
    </mat-sidenav>
    <mat-sidenav-content cdkScrollable fxLayout="column" fxFlexFill fxFlex>
        <app-main-toolbar [drawer]="drawer" [isHandset$]="isHandset$" fxFlex="nogrow"></app-main-toolbar>
        <div id="container" fxFlex [ngStyle]="(isHandset$ | async) ? {'padding.px': 10} : {'padding.px': 20}">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>