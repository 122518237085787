import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';
import { NavigationConfiguration, navConfig, NavigationItemConfiguration } from '../../config/navigation.config';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {

  navigation: Array<NavigationConfiguration>;
  regional: any = null;

  constructor(
    private auth: AuthService
  ) {
    this.navigation = new Array();
  }

  ngOnInit(): void {
    this.navigation = new Array();
    this.ngChange();
  }

  ngChange(): void {
    this.auth.currentRegional().subscribe(result => {
      this.regional = result;
      this.auth.currentUser().subscribe(usuario => {
        if (usuario && this.regional) {
          let filteredItems: Array<NavigationItemConfiguration>;
          let filter: Array<NavigationConfiguration>;
          filter = navConfig.filter(nav => {
            filteredItems = nav.items.filter(item => {
              for (const permiso of usuario.permisos) {
                if (item.permissions.includes(permiso)) {
                  return true;
                }
              }
              return false;
            });
            nav.items = filteredItems;
            return filteredItems.length > 0;
          });
          this.navigation = filter;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.navigation = null;
  }

}
