import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as Highcharts from "highcharts";
import { Series } from '../../models/auxiliar';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';
import { ApiService } from '../../services/api.service';
import { Regional } from 'src/app/modules/administracion/models/regional';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  options: any;
  options2: any;
  options3: any;
  options4: any;

  x = [];
  mes_toma = [];
  edad: Number = null;
  tiempoEspera: String = 'none';
  frecuenciaAtenciones: String = 'none';
  atenciones: String = 'none';
  turnosvs: String = 'none';

  regional: Regional;

  constructor(
    public auth: AuthService,
    private api: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.auth.currentUser().subscribe(result => {
      if (result != null) {
        this.auth.currentRegional().subscribe(regional => {
          if (regional != null) {
            this.api.tiempoEsperaAtencion(result.medico_id).subscribe(result2 => {
              this.tiempoEsperaPacientes(result2);
            });
            this.api.frecuenciaAtencionesPaciente(result.medico_id).subscribe(result3 => {
              this.tiempoEsperaPacientes2(result3);
            });
            this.api.frecuenciaAtencionesAtendidas(result.medico_id).subscribe(result4 => {
              this.atencionesAtendidas(result4);
            });
            this.api.frecuenciaTurnosLibresVsReservados(result.medico_id).subscribe(result5 => {
              this.turnosLibresVsReservados(result5);
            });
          }
        });

      }
    });
  }

  tiempoEsperaPacientes(result: any) {
    try {
      // Definir el orden deseado
      const order = ["15 minutos", "30 minutos", "45 minutos", "1 hora", "Mayor a 1 hora"];

      // Inicializar el array resultante con ceros
      let resultArray = Array(order.length).fill(0);

      // Recorrer el JSON y asignar las cantidades al array resultante en el orden correcto
      result.forEach(item => {
        const index = order.indexOf(item.intervalo);
        if (index !== -1) {
          resultArray[index] = item.cantidad;
        }
      });
      if (resultArray.length == 0) {
        resultArray = [];
      }
      this.options2 = {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Atencion al suario/a',
        },
        xAxis: {
          categories: ['15min', '30min', '45min', '1hora', 'Más de 1 hora'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total de pacientes',
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        series: [
          {
            name: "Tiempo",
            colorByPoint: true,
            data: resultArray,
          }
        ],
        credits: null
      };
      this.tiempoEspera = 'initial';
      const chart = Highcharts.chart('chart', this.options2)
    } catch (error) {
      //console.error("Error en Highcharts:", error);
    }
  }


  tiempoEsperaPacientes2(result: any) {
    try {
      let resultArray = [result["1 vez"], result["2 veces"], result["3 veces"], result["4 veces"], result["5 veces"], result["más de 5"],]
      this.options = {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Promedio de repeticiones de atención médica por usuario/a en el mes actual',
        },
        xAxis: {
          categories: ["1 atencion", "2 atenciones", "3 atenciones", "4 atenciones", "5 atenciones", "Más de 5 atenciones"],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total de pacientes',
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        series: [
          {
            name: "Atenciones",
            data: resultArray,
            colorByPoint: true,
          }
        ],
        credits: null
      };
      this.frecuenciaAtenciones = 'initial';
      const chart2 = Highcharts.chart('chart2', this.options)
    } catch (error) {
      //console.error("Error en Highcharts:", error);
    }
  }

  atencionesAtendidas(result: any) {
    try {
      let resultArray = [
        result["atendido"],
        result["sin_atender"],
      ];
      const totalPacientes = resultArray.reduce((acc, value) => acc + value, 0);
      const porcentajes = resultArray.map((value) => (value / totalPacientes) * 100);
      const categorias = [
        "Atendidas " + porcentajes[0] + "%",
        "Sin atender " + porcentajes[1] + "%",
      ];
      this.options3 = {
        chart: {
          type: "pie",
        },
        title: {
          text: "Porcentaje de usuarios/as atendidos en el mes actual",
        },
        series: [
          {
            name: "Atenciones",
            data: categorias.map((categoria, index) => ({
              name: categoria,
              y: porcentajes[index],
            })),
            colorByPoint: true,
          },
        ],
        credits: null,
      };
      this.atenciones = "initial";
      const chart3 = Highcharts.chart("chart3", this.options3);
    } catch (error) {
      //console.error("Error en Highcharts:", error);
    }
  }

  turnosLibresVsReservados(result: any) {
    try {
      // Transformar los datos en series
      const seriesData = result.map(item => ({
        name: item.fecha,
        y: item.total_turnos
      }));
      const seriesDataReservados = result.map(item => ({
        name: item.fecha,
        y: item.total_turnos_reservados
      }));
      const seriesDataReservadosAtendidos = result.map(item => ({
        name: item.fecha,
        y: item.total_turnos_con_atenciones_atendidas
      }));
      // Crear la configuración del gráfico
      this.options4 = {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Productividad por fecha'
        },
        xAxis: {
          categories: result.map(item => item.fecha)
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total de turnos'
          }
        },
        legend: {
          reversed: true,
        },
        series: [
          {
            name: 'Capacidad de atención',
            color: '#999eff',
            data: seriesData,
            index: 0
          },
          {
            name: 'Rendimiento',
            data: seriesDataReservados,
            color: '#ff99d1',
            index: 1
          },
          {
            name: 'Atenciones realizadas',
            data: seriesDataReservadosAtendidos,
            color: '#F7A35C',
            index: 2
          }
        ],
        credits: null
      };
      this.turnosvs = "initial";
      const chart4 = Highcharts.chart("chart4", this.options4);
    } catch (error) {
      //console.error("Error en Highcharts:", error);
    }
  }
}