import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/authentication/services/auth.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PerfilComponent } from '../perfil/perfil.component';
import { PerfilUsuarioComponent } from '../perfil-usuario/perfil-usuario.component';


@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit {

  @Input() drawer: MatSidenav;
  @Input() isHandset$: Observable<boolean>;

  constructor(
    private auth: AuthService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.auth.logout();
    this.router.navigate(['']);
    this.router.navigate(['/auth/login']);
  }

  openDialogResultado(): void {
    let data: any = {
    };
    const dialogRef = this.dialog.open(PerfilComponent, {
      width: '1000px',
      maxWidth: '95%',
      data: data
    });
    dialogRef.afterClosed().subscribe(resultDialog => {
      if(resultDialog == true){
        this.logout();
      }
    });
  }

  openDialogPerfilUsuario(): void {
    let data: any = {
    };
    const dialogRef = this.dialog.open(PerfilUsuarioComponent, {
      width: '1300px',
      maxWidth: '95%',
      data: data
    });
    dialogRef.afterClosed().subscribe(resultDialog => {
      if(resultDialog == true){
        this.logout();
      }
    });
  }
}
